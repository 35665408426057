import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectLanguage } from "../store/languageSlice";
import * as Constants from "../config/Constants";

const Container = styled.div`
  margin: auto;
  height: 60px;
  background-color: ${Constants.FooterBackgroundColor};
`;
const Wrapper = styled.div`
  padding: 15px 15px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = () => {
  const { t, i18n } = useTranslation();
  const getLanguage = useSelector(selectLanguage);
  useEffect(() => i18n.changeLanguage(getLanguage), []);

  return (
    <Container>
      <Wrapper>&copy; 2021 {t("footer_reserved")}</Wrapper>
    </Container>
  );
};

export default Footer;
