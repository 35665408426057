import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import pageNotFoundImg from "./../assets/images/page-not-found.jpg";
import { useSelector } from "react-redux";
import { selectLanguage } from "../store/languageSlice";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const InfoBlock = styled.div`
  font-size: 35px;
`;
const ImgBlock = styled.img`
  width: 85%;
  height: 300px;
  margin: 30px;
`;

const PageNotFound = () => {
  const { t, i18n } = useTranslation();
  const getLanguage = useSelector(selectLanguage);
  useEffect(() => i18n.changeLanguage(getLanguage), []);
  return (
    <Container>
      <InfoBlock>{t("pageNotFound")}</InfoBlock>
      <ImgBlock alt={t("pageNotFound")} src={pageNotFoundImg} />
    </Container>
  );
};

export default PageNotFound;
