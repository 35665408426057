import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Pagination } from "@mui/material";
import usePagination from "../components/Pagination";
import { useTranslation } from "react-i18next";
import { useDispatch} from "react-redux";
import { setMyCourses} from "../store/myCoursesSlice";
import * as Constants from "../config/Constants";
import { NavLink } from "react-router-dom";
import {
  Container,
  Page,
  Left,
  MainBody,
  AdsBody,
  Right,
  Header,
  FrameBorder,
  Ads,
} from "../components/common/Mainstyle";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: white;
  justify-content: center;
`;

const Box = styled.div`
  margin: 10px 10px;
  width: 240px;
  height: 240px;
  padding-top: 15px;
  border: 1px solid #d1c4c4;
  border-radius: 4%;
`;

const ImgCourse = styled.img`
  margin-top: 13px;
  width: 100%;
  height: 80%;
  border-radius: 0 0 4% 4%;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const Line = styled.div`
  display: flex;
  width: 100%;
`;

const Title = styled.div`
  border: none;
  margin: 0px 10% 0px 10%;
`;

const DateDis = styled.div`
  display: flex;
  font-size: 10px;
  color: #999999;
  margin-top: 8px;
  margin-left: 23px;
`;

const SearchResults = () => {
  const location = useLocation();
  const searchTerm = new URLSearchParams(location.search).get("query");
  const [result, setResult] = useState([]);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  let [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const NUM_OF_COURSE_PER_PAGE = process.env.REACT_APP_NUM_OF_COURSE_PER_PAGE;
  const count = Math.ceil(result.length / NUM_OF_COURSE_PER_PAGE);
  const pageData = usePagination(result, NUM_OF_COURSE_PER_PAGE);
  const EDU_STORAGE_URL = process.env.REACT_APP_EDU_STORAGE_URL;
  const PATH_PREFIX_PREVIEW = EDU_STORAGE_URL + Constants.PATH_PREFIX_PREVIEW;
  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          EDU_API_BASE_URL + "course/search",
          {
            params: {
              orgId: 1,
              skey: "COURSE_TEACHER_NAME",
              svalue: searchTerm,
            },
          }
        );
        setResult(response.data.data);
        dispatch(setMyCourses(response.data.data));
        setError("");
      } catch (error) {
        setError(error.message);
        console.error("Error fetching search results", error);
      }
    };

    fetchData();
  }, [searchTerm, dispatch]);

  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };

  return (
    <Container>
      <Left />
      <MainBody>
        <FrameBorder>
          <Header> {t("searchResult")}</Header>
          <Wrapper>
            {error ? (
              <div>{error}</div>
            ) : pageData.currentData() ? (
              pageData.currentData().map((course) => (
                <Box key={course.courseId}>
                  <Line>
                    <Title>{course.courseName}</Title>
                  </Line>
                  <Line>
                    <DateDis>
                      {course.startDate} to {course.endDate} &nbsp;{" "}
                      {course.timeRange}
                    </DateDis>
                  </Line>
                  <NavLink to={"/course-detail/" + course.courseId}>
                    <ImgCourse
                      src={PATH_PREFIX_PREVIEW + course.previewImage}
                    />
                  </NavLink>
                </Box>
              ))
            ) : (
              "No Data Found"
            )}
          </Wrapper>
          <Page>
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </Page>
        </FrameBorder>
      </MainBody>
      <AdsBody>
        <FrameBorder>
          <Ads src={Constants.AdsImg_1} />
        </FrameBorder>
      </AdsBody>
      <Right />
    </Container>
  );
};

export default SearchResults;
