import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import FormInput from "../components/common/FormInput";
import { useSelector } from "react-redux";
import { selectLanguage } from "../store/languageSlice";
import * as Constants from "../config/Constants";
import {
  Container,
  Form,
  Title,
  Button,
} from "../components/common/LoginRegister";

const NavToLogin = styled.div`
  font-size: 12px;
  color: #105f38;
  margin-bottom: 10px;
`;
const TermOfCondition = styled.div`
  font-size: 9px;
  color: #105f38;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
`;
const Word = styled.div`
  margin: 0px 5px;
`;
const Error = styled.div`
  font-size: 12px;
  color: ${(props) => (props.success ? "green" : "red")};
  margin-left: 10px;
  display: block;
  text-align: left;
`;

const Register = () => {
  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;
  const EDU_ORG_ID = process.env.REACT_APP_EDU_ORG_ID;

  const initRegInfo = {
    organization: { orgId: EDU_ORG_ID },
    campusId: 1,
    userName: "",
    email: "",
    password: "",
    pass2: "",
    validateErr: "",
    roles: [
      {
        roleName: "STUDENT",
      },
    ],
  };
  const [posted, setPosted] = useState(false);
  const [errors, setErrors] = useState([]);
  const [regInfo, setRegInfo] = useState(initRegInfo);
  const { t, i18n } = useTranslation();
  const getLanguage = useSelector(selectLanguage);

  useEffect(() => i18n.changeLanguage(getLanguage), []);

  const inputs = [
    {
      id: 1,
      name: "userName",
      type: "text",
      placeholder: "UserName",
      label: "UserName",
      pattern: "^[a-zA-Z0-9!]{3,16}$",
      validateErr: "userName is between 3-16 characters or digits",
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
      label: "Email",
      pattern:
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
      validateErr: "invalid email format",
    },
    {
      id: 3,
      name: "password",
      type: "password",
      placeholder: "Password",
      label: "Password",
      pattern: "^[a-zA-Z0-9!@#$%^&*()_+=?]{8,16}$",
      validateErr: "password is between 8-16 characters",
    },
    {
      id: 4,
      name: "pass2",
      type: "password",
      placeholder: "Confirm Password",
      label: "Confirm Password",
      pattern: regInfo.password,
      validateErr: "password not match",
    },
  ];

  const callSignUpApi = () => {
    console.log(regInfo);
    const axiosConfig = {...Constants.AXIOS_CONFIG_BASE};
    axiosConfig.headers["x-token-subject"] = regInfo.email
    axios
      .post(
        EDU_API_BASE_URL + "auth-edu/signup",
        regInfo,
        axiosConfig
      )
      .then((response) => {
        console.log(response);
        setRegInfo(initRegInfo);
        setPosted(true);
        setErrors([{ message: "Your account has been created successfully" }]);
        window.location = "/login";
      })
      .catch((error) => {
        setPosted(false);
        if ((error.response.status = 400)) {
          console.log(error.response);
          setErrors(error.response.data.notifications);
        } else {
          setErrors([{ message: error.message }]);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      e.target.userName.value === "" ||
      e.target.email.value === "" ||
      e.target.password.value === "" ||
      e.target.pass2.value === ""
    ) {
      setPosted(false);
      setErrors([{ message: "Don't leave fields empty" }]);
      return;
    }
    callSignUpApi();
  };

  const onChange = (e) => {
    setRegInfo({ ...regInfo, [e.target.name]: e.target.value });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Title>{t("signup")}</Title>
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={regInfo[input.name]}
            onChange={onChange}
          />
        ))}
        <Button type="submit">{t("signup")}</Button>
        <NavToLogin>
          {t("haveAccount")} ? <NavLink to="/login">{t("login")}</NavLink>
        </NavToLogin>
        <TermOfCondition>
          <NavLink to="/term">{t("term")}</NavLink>
          <Word>and</Word>
          <NavLink to="/term">{t("policy")}</NavLink>
        </TermOfCondition>
        {errors.map((error) => (
          <Error key={error.message} success={posted}>
            {error.message}
          </Error>
        ))}
      </Form>
    </Container>
  );
};

export default Register;
