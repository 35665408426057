import React from "react";
import styled from "styled-components";
import errorPageImg from "./../assets/images/error-page.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const InfoBlock = styled.div`
  font-size: 35px;
`;
const ImgBlock = styled.img`
  width: 85%;
  height: 300px;
  margin: 30px;
`;

const ErrorPage = () => {
  return (
    <Container>
      <InfoBlock></InfoBlock>
      <ImgBlock alt={`Error Page`} src={errorPageImg} />
    </Container>
  );
};

export default ErrorPage;
