import * as Constants from "../../config/Constants";
import {
  FrameBorder,
  Title,
  Name,
  Teacher,
  TeacherImg,
  TeacherName,
} from "./Mainstyle";

import { useTranslation } from "react-i18next";

const TeacherDisplay = ({ teachers, role }) => {
  const { t, i18n } = useTranslation();

  const EDU_STORAGE_URL = process.env.REACT_APP_EDU_STORAGE_URL;
  const PATH_PREFIX_AVATAR = EDU_STORAGE_URL + Constants.PATH_PREFIX_DEFAULT;

  return (
    <FrameBorder>
      <Title>{t(role)}</Title>
      {teachers
        ? teachers.map((teacher) => (
            <Teacher key={teacher.employeeId}>
              <TeacherImg
                src={
                  teacher.user.avadarImage
                    ? PATH_PREFIX_AVATAR + teacher.user.avadarImage
                    : teacher.gender == 1
                    ? Constants.TeacherManDefaultImg
                    : Constants.TeacherWomanDefaultImg
                }
              />
              <TeacherName>
                {teacher.firstName} {teacher.lastName}
              </TeacherName>
            </Teacher>
          ))
        : ""}
    </FrameBorder>
  );
};

export default TeacherDisplay;
