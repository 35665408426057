import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import LogoImg from "./../assets/images/lnova-logo.jpg";
import HamberImg from "./../assets/images/menu-bars.svg";
import { useTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";
import { mobile } from "./../assets/css/responsive";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser } from "../store/userSlice";
import { clearCourse } from "../store/courseSlice";
import { clearLesson } from "../store/lessonSlice";
import { setLanguage } from "../store/languageSlice";
import { useNavigate } from "react-router-dom";
import { setTeacher } from "../store/teacherSlice";
import { setClassmate } from "../store/classmateSlice";
import * as Constants from "../config/Constants";
import { BiCart, BiUserCircle } from "react-icons/bi";
import { clearMyCourses } from "../store/myCoursesSlice";

const Container = styled.div`
  width: 100%;
  height: 80px;
  z-index: -1;
  background-color: ${Constants.HeaderBackgroundColor};
`;
const Wrapper = styled.div`
  margin-inline: auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mobile({ width: "96%" })}
`;
const Left = styled.div`
  flex: 1;
`;
const Center = styled.div`
  flex: 2;
`;
const Right = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: right;
  position: relative;
`;
const StyledA = styled.a`
  text-decoration: none;
  display: block;
  text-align: left;
  background-color: #479791;
  padding: 5px;
`;
const DropDownContent = styled.div`
  display: none;
  margin-top: 2px;
  position: absolute;
  right: 0;
`;
const HanbergBtn = styled.div`
  display: none;
  ${mobile({ display: "inline-block" })};
  background-image: url(${HamberImg});
  background-position: right center;
  background-repeat: no-repeat;
  content: "";
  height: 3.75em;
  vertical-align: top;
  width: 2em;
  margin: 5px;
  position: relative;
  &:hover ${DropDownContent} {
    display: block;
    position: absolute;
    top: 90%;
    width: 150px;
  }
`;
const Logo = styled.img`
  float: left;
  width: 120px;
  height: 70px;
  ${mobile({ width: "80px" })}
`;
const SearchContainer = styled.div`
  display: flex;
  width: 80%;
  height: 36px;
  border: 1px solid green;
  border-radius: 20px;
  background: white;
  ${mobile({ display: "none" })}
`;
const SearchIcon = styled(BiSearch)`
  font-size: 20px;
  color: grey;
  padding: 10px;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
`;
const Input = styled.input`
  margin-left: 15px;
  width: 100%;
  border: none;
  font-size: 16px;
  color: grey;
  height: 16px;
  background: transparent;
  border-radius: 15px;
  &:focus {
    outline: none;
  }
`;
const Button = styled.button`
  font-size: 13px;
  color: ${Constants.ButtonColor};
  width: 110px;
  height: 33px;
  border: 1px solid #b3a9a9;
  border-radius: 20px;
  cursor: pointer;
  background: ${Constants.ButtonBackgroundColor};
  &:hover {
    background: ${Constants.ButtonHoverBackgroundColor};
  }
  margin-right: 3%;
  margin-top: 5px;
  display: ${(props) => (props.hide ? "none" : "block")};
  ${mobile({ width: "80px" })}
`;
const BtnGroup = styled.div`
  display: flex;
  ${mobile({ display: "none" })}
`;
const NavLink = styled.a``;
const Profile = styled.div`
  position: relative;
  cursor: pointer;
  display: flex; // Ensure flex layout for alignment
  align-items: center; // Align items vertically in the center
`;
const ProfileIcon = styled(BiUserCircle)`
  font-size: 20px;
  color: white;
  padding: 10px;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  ${mobile({ fontSize: "18px", padding: "8px" })}
`;
const ProfileCard = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  top: 50px;
  left: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 220px;
  z-index: 20;
  padding: 10px;
  @media (max-width: 1000px) {
    left: auto;
    right: 20px;
  }
`;
const CardHeader = styled.div`
  font-size: 16px;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 10px;
`;
const CardItem = styled.a`
  color: #555;
  padding: 8px 12px;
  display: block;
  text-decoration: none;
  background-color: ${(props) => (props.logout ? "transparent" : "white")};
  &:hover {
    background-color: ${(props) => (props.logout ? "red" : "#f6f6f6")};
    color: ${(props) => (props.logout ? "white" : "#555")};
  }
`;
const BiCartIcon = {
  padding: "6% 2% 3% 1%",
  cursor: "pointer",
};
const LanguageSelect = styled.select`
  font-size: 13px;
  color: ${Constants.ButtonColor};
  width: 110px;
  height: 33px;
  border: 1px solid #b3a9a9;
  border-radius: 20px;
  cursor: pointer;
  background: ${Constants.ButtonBackgroundColor};
  &:hover {
    background: ${Constants.ButtonHoverBackgroundColor};
  }
  margin-right: 3%;
  margin-top: 5px;
  display: ${(props) => (props.hide ? "none" : "block")};
  ${mobile({ display: "none"})}
  appearance: none;
  padding-left: 26px;
`;
// const LanguageButton = styled(IoLanguage)`
//   display: none;
//   ${mobile({ display: "inline-block" })};
//   font-size: 2em;
//   margin: 5px;
//   cursor: pointer;
// `;
const LanguageButton = styled.div`
  display: none;
  ${mobile({ display: "inline-block" })};
  font-size: 1em;
  margin: 5px;
  cursor: pointer;
  color: white;
`;
const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const profileRef = useRef(null);
  // Add event listener when component mounts and clean up on unmount
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const changeLanguage = (e) => {
    const lang = e.target.value;
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  const user = useSelector(selectUser);
  console.log("header-user!!!", user);

  const regiser = () => {
    window.location = "/register";
  };

  const login = () => {
    window.location = "/login";
  };

  const home = () => {
    window.location = "/";
  };

  const about = () => {
    window.location = "/about";
  };

  const contact = () => {
    window.location = "/contact";
  };

  const myCourses = () => {
    if (user.roles[0].roleName == "STUDENT")
      window.location = "/student-course";
    else if (user.roles[0].roleName == "TEACHER")
      window.location = "/teacher-course";
  };

  const logoutF = () => {
    dispatch(logout());
    dispatch(clearLesson());
    dispatch(clearCourse());
    dispatch(setTeacher(null));
    dispatch(setClassmate(null));
    dispatch(clearMyCourses());
    navigate("/home");
  };

  const handleSearch = async () => {
    if (searchTerm === "") return;
    navigate(`/search-results?query=${searchTerm}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Container>
      <Wrapper>
        <Left>
          <NavLink href="/">
            <Logo alt={`logo`} src={LogoImg} />
          </NavLink>
        </Left>
        <Center>
          <SearchContainer>
            <Input
              placeholder={t("course_search_hint")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <SearchIcon onClick={handleSearch} />
          </SearchContainer>
        </Center>
        <Right>
          <LanguageButton
            onClick={() => {
              const lang = i18n.language === "en" ? "zh" : "en";
              i18n.changeLanguage(lang);
              dispatch(setLanguage(lang));
            }}
          >
            {i18n.language === "en" ? "中" : "En"}
          </LanguageButton>
          <HanbergBtn>
            <DropDownContent>
              <StyledA onClick={home}>{t("home")}</StyledA>
              <StyledA onClick={about}>{t("about_us")}</StyledA>
              <StyledA onClick={contact}>{t("contact_us")}</StyledA>
              {user == null ? (
                <>
                  <StyledA onClick={login}>{t("login")}</StyledA>
                  <StyledA onClick={regiser}>{t("register")}</StyledA>
                </>
              ) : (
                <>
                  <StyledA onClick={myCourses}>{t("myCourses")}</StyledA>
                  <StyledA href="#">{t("profile")}</StyledA>
                  <StyledA onClick={logoutF} value="logout">
                    {t("logout")}
                  </StyledA>
                </>
              )}
            </DropDownContent>
          </HanbergBtn>
          {user == null ? (
            <BtnGroup>
              <Button hide={false} onClick={login}>
                {t("login")}
              </Button>
              <Button hide={false} onClick={regiser}>
                {t("register")}
              </Button>
            </BtnGroup>
          ) : (
            <BtnGroup>
              <Button hide={false} onClick={myCourses}>
                {t("myCourses")}
              </Button>
              <BiCart color="white" size={"20px"} style={BiCartIcon} />
              <Profile ref={profileRef} onClick={toggleDropdown}>
                <ProfileIcon />
                <ProfileCard show={showDropdown}>
                  <CardHeader>{t("accountSettings")}</CardHeader>
                  <CardItem href="#">{t("profile")}</CardItem>
                  <CardItem logout={true} onClick={logoutF}>
                    {t("logout")}
                  </CardItem>
                </ProfileCard>
              </Profile>
            </BtnGroup>
          )}
          <LanguageSelect value={i18n.language} onChange={changeLanguage}>
            <option value="en">ENGLISH</option>
            <option value="zh">简体中文</option>
          </LanguageSelect>
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Header;
