
import {
    DateDisplay,  LessonName,
   
} from "./Mainstyle";
import { useTranslation } from "react-i18next";


const DateList = ({ lesson }) => {
    const { t, i18n } = useTranslation();
 

    return (

        <DateDisplay>
            <LessonName>
                {lesson.lessonName}
            </LessonName>
            <LessonName>
                {lesson.lessonDate}
            </LessonName>
            <LessonName>
                {lesson.startTime} to {lesson.endTime}
            </LessonName>
            <LessonName>
           { t('due')}:   { lesson.homeworkDue }
            </LessonName>


        </DateDisplay>

    )
};



export default DateList