import React from "react";
import styled from "styled-components";
import Pic from "./../assets/images/home-banner1-1200x450.jpg";

const Container = styled.div`
  height: 400px;
  background-color: #e9f3f0;
`;
const Image = styled.img`
  margin: auto;
  width: 100%;
  height: 400px;
`;
const HomeSlide = () => {
  return (
    <Container>
      <Image alt={`slide-1`} src={Pic} />
    </Container>
  );
};

export default HomeSlide;
