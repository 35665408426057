import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import * as Constants from "../config/Constants";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../store/userSlice";
import { selectLanguage } from "../store/languageSlice";
import { setCourse } from "../store/courseSlice";
import { setTeacher } from "../store/teacherSlice";
import { setClassmate } from "../store/classmateSlice";
import { selectMyCourses } from "../store/myCoursesSlice";
import { mobile } from "../assets/css/responsive";
import Link from "@mui/material/Link";
import NavigatorBar from "../components/common/NavigatorBar";
import {
  Container,
  Left,
  MainBody,
  AdsBody,
  Right,
  FrameBorder,
  Navigator,
  Wrap,
  Wrapper,
  Title,
  Img,
  CourseDesc,
  Name,
  DateDisplay,
  TeacherName,
  TeacherImg,
  LessonName,
  Label,
  Ads,
} from "../components/common/Mainstyle";

const Category = styled.div`
  display: inline;
  border: solid 1px;
  width: 70px;
  height: 12px;
  font-size: 11px;
  color: #cf1212;
`;
const Box = styled.div`
  margin: 0px 0px 0px 5%;
  text-align: left;
`;
const DateBox = styled.div`
  display: inline;
  font-size: 13px;
  color: #999999;
  ${mobile({ display: "block", textAlign: "center", margin: "3%" })}
`;
const NameBox = styled.div`
  display: inline;
  margin-left: 1%;
  margin-right: 1%;
`;
const Tab = styled.div`
  border: none;
  margin-bottom: 20px;
  display: flex;
  background-color: #e2dfdf;
`;
const LessonRow = styled.div`
  border: none;
  margin: 0 8% 6% 8%;
  display: flex;
`;
const LessonNum = styled.div`
  border: none;
  margin-right: 4%;
  font-size: 13px;
  color: #999999;
  ${mobile({ width: "35%" })}
`;
const Price = styled.div`
  border-top: 3px solid red;
  padding: 5% 10% 5% 10%;
  font-size: 26px;
  font-weight: bold;
  height: 100%;
  color: #f17623;
`;
const LessonCount = styled.div`
  border: none;
  margin: 5% 10% 5% 10%;
  font-size: 15px;
  font-weight: bold;
  color: #f17126;
`;
const AddCourseButton = styled.button`
  border: none;
  margin-left: 10%;
  margin: 10% 10%;
  width: 40%;
  border-radius: 30px;
  color: white;
  background-color: #15887c;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
    color: #ebe2e2;
  }
`;
const GiftImg = styled.img`
  border: none;
  width: 35%;
  height: 35%;
  border-radius: 50%;
`;
const GiftBorder = styled.div`
  border: 1px solid #c3c3c3;
  margin-bottom: 30px;
  box-shadow: 1px 1px 4px #bbb0b0;
  border-radius: 5px;
  padding: 5px 20px 20px 20px;
`;

const CourseDetail = () => {
  const getLanguage = useSelector(selectLanguage);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const myCourses = useSelector(selectMyCourses);
  const role = user ? user.roles[0].roleName : "";
  const { courseId } = useParams();
  const myref = useRef();
  const navigate = useNavigate();

  const [courseData, setCourseData] = useState({});
  const [lessonsData, setLessonsData] = useState({});
  const [teacherData, setTeacherData] = useState({});
  const [courseFiles, setCourseFiles] = useState([]);
  const [fileCount, setFileCount] = useState(0);
  const [errors, setErrors] = useState([]);

  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;
  const EDU_STORAGE_URL = process.env.REACT_APP_EDU_STORAGE_URL;
  const PATH_PREFIX_AVATAR = EDU_STORAGE_URL + Constants.PATH_PREFIX_DEFAULT;
  const AXIOS_CONFIG = {...Constants.AXIOS_CONFIG_BASE};
  AXIOS_CONFIG.headers["x-token-subject"] = user.email;

  const handleClick = () => {
    console.log("hey man")
    if (!user) {
      navigate("/login");
      return;
    }

    const status = courseData.price === 0.0 ? 4 : 1;
    const data = {
      description: `student ${user.userId} selected course ${courseId}`,
      course: { courseId: courseId },
      student: { studentId: user.student.studentId},
      status: status,
      banned: false,
    };

    axios
      .post(`${EDU_API_BASE_URL}admin/link-course-student`, data, AXIOS_CONFIG)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          navigate("/student-course");
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, ` handleJoinCourse: ${error.message}`]);
      });
  };

  const showJoinCourseButton = () => {
    const currentDate = new Date();
    const courseEndDate = new Date(courseData.endDate);
    if (isCourseOnMyList() || courseEndDate < currentDate) {
    // if (isCourseOnMyList()) {
      return false
    } else {
      return true
    }
  };

  const NavigatorHtml = () => {
    return (
      <Navigator>
        <Link underline="hover" color="inherit" href="/">
          {t("home_breadcrumb")}
        </Link>/
        {user && (
          <Link
            underline="hover"
            color="inherit"
            href={role == "STUDENT" ? "/student-course/" : "/teacher-course/"}
          >
            {role == "STUDENT" ? t("studentCourse") : t("teacherCourse")}/
          </Link>
        )}
        <Link underline="none" color="text.primary" aria-current="page">
          {t("course_detail")}
        </Link>
      </Navigator>
    );
  };

  const fetchCourseFilesByCourseId = () => {
    axios
      .get(EDU_API_BASE_URL + "course/" + courseId + "/files", Constants.AXIOS_CONFIG_BASE)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setFileCount(response.data.data.length);
          setCourseFiles(response.data.data);
          console.log("course-id-files-res", response.data.data);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchCourseFilesByCourseId"]);
        console.log(error);
      });
  };

  const fetchCourseById = () => {
    setErrors([]);
    axios
      .get(EDU_API_BASE_URL + "course/" + courseId, Constants.AXIOS_CONFIG_BASE)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setCourseData(response.data.data[0]);
          dispatch(setCourse(response.data.data[0]));
          console.log("course-id-res", response.data.data[0]);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchCourseById"]);
        console.log(errors);
      });
  };

  const fetchTeachersByCourseId = () => {
    axios
      .get(EDU_API_BASE_URL + "course/" + courseId + "/teachers", Constants.AXIOS_CONFIG_BASE)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setTeacherData(response.data);
          dispatch(setTeacher(response.data.data));
          console.log("course-id-teachers-res", response.data.data);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchTeachersByCourseId"]);
        console.log(error);
      });
  };

  const fetchClassmatesByCourseId = async () => {
    axios
      .get(EDU_API_BASE_URL + "course/" + courseId + "/students", Constants.AXIOS_CONFIG_BASE)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          dispatch(setClassmate(response.data.data));
          console.log("course-id-students-res", response.data.data);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchClassmatesByCourseId"]);
        console.log(error);
      });
  };

  const fetchLessonsByCourseId = () => {
    axios
      .get(EDU_API_BASE_URL + "course/" + courseId + "/lessons", Constants.AXIOS_CONFIG_BASE)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setLessonsData(response.data);
          console.log("course-id-lessons-res", response.data.data);
          console.log("isCourseOnMyList", isCourseOnMyList());
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchLessonsByCourseId"]);
        console.log(error);
      });
  };

  const isCourseOnMyList = () => {
    let rtn = false;
    if (myCourses != undefined) {
      for (let i = 0; i < myCourses.length; i++) {
        let c = myCourses[i];
        if (c.courseId == courseId) {
          rtn = true;
        }
       }
    }
     return rtn;
  }

  useEffect(() => {
    i18n.changeLanguage(getLanguage);
    fetchCourseById();

    if (errors.length === 0) {
      fetchTeachersByCourseId();
      fetchClassmatesByCourseId();
      fetchLessonsByCourseId();
      fetchCourseFilesByCourseId();
    }
  }, [fileCount]);

  const clickLabel = () => {
    myref.current.scrollIntoView({ behavior: "smooth" });
  };

  if (errors.length > 0) {
    return (
      <div>
        {errors.map((error) => (
          <div>{error}</div>
        ))}
      </div>
    );
  } else {
    const course = courseData;
    const lessons = lessonsData.data;
    const teachers = teacherData.data;
    return (
      <Container>
        <Left />
        <MainBody>
          <NavigatorBar content={true} navigatorHtml={NavigatorHtml} />
          <FrameBorder>
            {course ? (
              <Box>
                <Category>
                  {course.courseCategory && course.courseCategory.courseCatName}
                </Category>
                <NameBox> {course.courseName}</NameBox>
                <DateBox>
                  ({course.startDate} {course.timeRange})
                </DateBox>
              </Box>
            ) : ("")}
            {teachers
              ? teachers.map((teacher) => (
                <Wrap key={teacher.employeeId}>
                  <TeacherImg
                    src={
                      teacher.user.avadarImage
                        ? PATH_PREFIX_AVATAR + teacher.user.avadarImage
                        : teacher.gender == 1
                          ? Constants.TeacherManDefaultImg
                          : Constants.TeacherWomanDefaultImg
                    }
                  />
                  <Name> &nbsp; &nbsp; &nbsp; &nbsp; </Name>
                  <TeacherName>
                    {teacher.firstName} {teacher.lastName}
                  </TeacherName>
                  <TeacherName>{teacher.achievement}</TeacherName>
                </Wrap>
              ))
              : ""}
          </FrameBorder>
          <FrameBorder>
            <Tab>
              <Label isClicked="true">{t("course_detail")}</Label>
              <Label onClick={clickLabel}>{t("course_outline")}</Label>
            </Tab>
            {courseFiles
              ? courseFiles.map((file) =>
                file.fileType === "jpg" || file.fileType === "png" ? (
                  <Img
                    alt="course img"
                    key={file.fileId}
                    src={EDU_STORAGE_URL + file.filePath + "/" + file.fileName}
                  />
                ) : (
                  <a
                    href={EDU_STORAGE_URL + file.filePath + "/" + file.fileName}
                    target="_blank"
                    download
                  >{file.fileName}</a>
                )
              )
              : ""}
            <br />
            <br />
            <Title>{t("course_detail")}</Title>
            <CourseDesc>{course.description}</CourseDesc>
          </FrameBorder>
          <FrameBorder>
            <Title ref={myref}>{t("course_outline")}</Title>
            {lessons
              ? lessons.map((lesson) => (
                <LessonRow key={lesson.lessonId}>
                  <LessonNum>
                    {t("lesson_num").replace("{num}", lesson.lessonSeqNum)}
                  </LessonNum>
                  <LessonNum>
                    <NavLink
                      to={
                        isCourseOnMyList() && role &&
                        (role == "TEACHER"
                          ? "/lesson-detail-th/"
                          : "/lesson-detail-st/") + lesson.lessonId
                      }
                    >
                      <LessonName>{lesson.lessonName}</LessonName>
                    </NavLink>
                  </LessonNum>
                  <DateDisplay>
                    {lesson.lessonDate} {lesson.startTime} - {lesson.endTime}
                  </DateDisplay>
                </LessonRow>
              ))
              : ""}
          </FrameBorder>
        </MainBody>
        <AdsBody>
          <NavigatorBar content={false} navigatorHtml={NavigatorHtml} />
          <GiftBorder>
            <Price>$ {course.price}</Price>
            <LessonCount>
              {t("course_period").replace("{num}", course.lessonCount)}
            </LessonCount>
            <Wrapper>
              {showJoinCourseButton() && (<AddCourseButton onClick={handleClick}>{t("btn_join_course")}</AddCourseButton>)}
              <GiftImg src={Constants.GiftImg_1} />
            </Wrapper>
          </GiftBorder>
          <FrameBorder>
            <Ads src={Constants.AdsImg_1} />
          </FrameBorder>
        </AdsBody>
        <Right />
      </Container>
    );
  }
};

export default CourseDetail;