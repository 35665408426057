import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Constants from "../config/Constants";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage } from "../store/languageSlice";
import { selectUser } from "../store/userSlice";
import { selectCourse } from "../store/courseSlice";
import { setLesson } from "../store/lessonSlice";
import { mobile } from "../assets/css/responsive";
import { selectClassmate } from "../store/classmateSlice";
import { useNavigate } from "react-router-dom";
import Message from "../components/common/Message";
import Link from "@mui/material/Link";
import NavigatorBar from "../components/common/NavigatorBar";
import { NavLink } from "react-router-dom";
import "../assets/css/formInput.css";
import {
  Container,
  Left,
  MainBody,
  AdsBody,
  Right,
  FrameBorder,
  Navigator,
  Img,
  DateDisplay,
  Title,
  Name,
  Teacher,
  TeacherName,
  TeacherImg,
  LessonName,
  HomeworkDesc,
  Button,
} from "../components/common/Mainstyle";

const UploadSize = styled.div`
  font-size: 11px;
  width: 110px;
  height: 23px;
  display: ${(props) => (props.hide ? "none" : "block")};
`;
const MaxWords = styled.p`
  font-size: 11px;
  color: #666666;
  height: 21px;
  line-height: 21px;
`;
const FileRow = styled.div`
  margin: 0 10% 2% 10%;
  display: flex;
  justify-content: space-between;
  ${mobile({ margin: "10px 10% 10px 6%" })};
`;
const SaveFile = styled.div`
  display: flex;
  margin: 3% 10% 3% 10%;
  justify-content: space-between;
`;
const FileName = styled.div`
  border: none;
  height: 33px;
  padding-top: 5px;
  font-size: 13px;
  color: #666666;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
const FileImg = styled.div`
  border: none;
  width: 25%;
`;

const LessonDetailTh = () => {
  const dispatch = useDispatch();
  const getLanguage = useSelector(selectLanguage);
  const { t, i18n } = useTranslation();
  const { lessonId } = useParams();
  const user = useSelector(selectUser);
  const students = useSelector(selectClassmate);
  const course = useSelector(selectCourse);
  const courseId = course ? course.courseId : "";
  const teacherId = user ? user.employeeId : "";

  const EDU_ORG_NUM = process.env.REACT_APP_EDU_ORG_NUM;
  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;
  const EDU_STORAGE_URL = process.env.REACT_APP_EDU_STORAGE_URL;
  const AXIOS_CONFIG = {...Constants.AXIOS_CONFIG_BASE};
  AXIOS_CONFIG.headers["x-token-subject"] = user.email;
  const PATH_PREFIX_AVATAR = EDU_STORAGE_URL + Constants.PATH_PREFIX_DEFAULT;

  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [homeworkData, setHomeworkData] = useState({});
  const [lessonData, setLessonData] = useState({});
  const [lessonFile, setLessonFile] = useState([]);
  const [lessonDescription, setLessonDescription] = useState("");
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState("");
  const [message, setMesasge] = useState("");
  const [show, setShow] = useState(false);
  const [dueDate, setDueDate] = useState("");
  
  const year = "" + new Date(course.startDate).getUTCFullYear();
  const month = ("0" + (new Date(course.startDate).getUTCMonth() + 1)).slice(-2);
  const yearMonth = year.substring(2) + month;

  const NavigatorHtml = () => {
    return (
      <Navigator>
        <Link underline="hover" color="inherit" href="/">
          {t("home_breadcrumb")}/
        </Link>
        {user && (
          <Link underline="hover" color="inherit" href="/teacher-course/">
            {t("teacherCourse")}/
          </Link>
        )}
        <Link
          underline="hover"
          color="inherit"
          href={"/course-detail/" + courseId}
        >
          {t("course_detail")}/
        </Link>
        <Link underline="none" color="text.primary" aria-current="page">
          {t("lesson_detail")}
        </Link>
      </Navigator>
    );
  };

  const fetchLessonByLessonId = async () => {
    axios
      .get(EDU_API_BASE_URL + "lesson/" + lessonId, AXIOS_CONFIG)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setLessonData(response.data.data[0]);
          setDueDate(response.data.data[0].homeworkDue);
          setLessonDescription(response.data.data[0].description);
          dispatch(setLesson(response.data.data[0]));
          console.log("lesson-by-lid-res", response.data.data[0].lessonName);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchLessonByLessonId"]);
        console.log("lesson-by-lid error", error);
      });
  };
  const fetchLessonFileByLessonId = async () => {
    axios
      .get(
        EDU_API_BASE_URL + "lesson/" + lessonId + "/files",
        AXIOS_CONFIG
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setLessonFile(response.data.data);

          console.log("lesson-files-by-lid-res", response.data.data);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchLessonFileByLessonId"]);
        console.log("lesson-files-by-lid error", error);
      });
  };

  const deleteFile = (fileId, fileName, filePath) => {
    window.confirm(t("deleteComfirm"))
      ? comfirmDelete(fileId, fileName, filePath)
      : cancelDelete();
  };

  const comfirmDelete = (fileId, fileName, filePath) => {
    axios
      .delete(
        EDU_API_BASE_URL +
          "lesson/file/remove?fid=" +
          fileId +
          "&file=" +
          fileName +
          "&path=" +
          filePath,
          AXIOS_CONFIG
      )
      .then((response) => {
        console.log("lesson-file-remove-res", response.data);
        fetchLessonFileByLessonId();
      })
      .catch((error) => {
        console.log("lesson-file-remove error", error);
      });
  };

  const cancelDelete = () => {};
  const uploadFile = (courseNum) => {
    window.confirm(t("uploadComfirm"))
      ? comfirmUpload(courseNum)
      : cancelUpload();
  };

  const comfirmUpload = (courseNum) => {
    const formData = new FormData();
    formData.append("file", file);
    console.log("upload-formData-req", formData);
    
    axios
      .post(
        EDU_API_BASE_URL +
          "lesson/file/upload?cid=" +
          courseId +
          "&lid=" +
          lessonId +
          "&path=upload/" +
          yearMonth +
          "/" +
          EDU_ORG_NUM + //O220101000000
          "/" +
          courseNum,
        formData,
        AXIOS_CONFIG
      )
      .then((response) => {
        console.log("lesson-file-upload-res", response.data);
        setFile("");
        setMesasge("Data uploaded successfully!");
        setError("");
        fetchLessonFileByLessonId();
      })
      .catch((error) => {
        setMesasge("Error, Data has not been upload!!");
        setError(error);
        console.log("lesson file upload error", error);
      });
  };

  const cancelUpload = () => {
    setFile(null);
  };

  const saveFile = (lessonDescription) => {
    const value = {
      description: lessonDescription,
      courseId: courseId,
      homeworkDue: dueDate,
      lessonId: lessonData.lessonId,
      lessonSeqNum: lessonData.lessonSeqNum,
      lessonName: lessonData.lessonName,
      lessonDate: lessonData.lessonDate,
      startTime: lessonData.startTime,
      endTime: lessonData.endTime,
      lessonDuratuin: lessonData.lessonDuratuin,
    };

    axios
      .post(EDU_API_BASE_URL + "lesson", value, AXIOS_CONFIG)
      .then((response) => {
        console.log("lesson-post-res", response.data);
        setMesasge(t("saveSuccess"));
        setError("");
        setShow(true);
        const timeId = () =>
          setTimeout(() => {
            setShow(false);
          }, 5000);
        timeId();
        fetchLessonByLessonId();
      })
      .catch((error) => {
        setMesasge(t("saveError"));
        setError(error);
        setShow(true);
        const timeId = () =>
          setTimeout(() => {
            setShow(false);
          }, 5000);
        timeId();
        console.log("post lesson error", error);
      });
  };

  const onChange = (e) => {
    const file = e.target.files[0];
    console.log("file: ", file);
    setFile(file);
  };

  const dateChange = (e) => {
    setDueDate(e.target.value);
  };

  const handleChange = (e) => {
    setLessonDescription(e.target.value);
  };

  useEffect(() => {
    i18n.changeLanguage(getLanguage);
    if (!user) navigate("/login");

    if (course) {
      if (errors.length === 0) {
        fetchLessonByLessonId();
        fetchLessonFileByLessonId();
      }
    }
  }, []);

  if (errors.length > 0) {
    return (
      <div>
        {errors.map((error) => (
          <div>{error}</div>
        ))}
      </div>
    );
  } else {
    return (
      <Container>
        <Left />
        <MainBody>
          {show ? <Message message={message} error={error} /> : ""}
          <NavigatorBar content={true} navigatorHtml={NavigatorHtml} />
          <FrameBorder>
            {course ? (
              <Title>
                <Name>
                  {t("lesson-prepare")} ( {course.courseName} )
                </Name>
                <DateDisplay>
                  <LessonName>{lessonData.lessonName}</LessonName>
                  <LessonName>{lessonData.lessonDate}</LessonName>
                  <LessonName>
                    {lessonData.startTime} to {lessonData.endTime}
                  </LessonName>
                  <LessonName>
                    {t("due")}:{" "}
                    <input type="date" value={dueDate} onChange={dateChange} />
                  </LessonName>
                </DateDisplay>
              </Title>
            ) : (
              ""
            )}
            {lessonFile
              ? lessonFile.map((file) => (
                  <FileRow key={file.fileId}>
                    {file.fileType === "jpg" || file.fileType === "png" ? (
                      <FileImg>
                        <Img
                          src={
                            EDU_STORAGE_URL +
                            file.filePath +
                            "/" +
                            file.fileName
                          }
                        />
                      </FileImg>
                    ) : (
                      <a
                        href={
                          EDU_STORAGE_URL + file.filePath + "/" + file.fileName
                        }
                        target="_blank"
                        download
                      >
                        <FileName>{file.fileName} </FileName>
                      </a>
                    )}
                    <Button
                      hide={false}
                      onClick={() =>
                        deleteFile(file.fileId, file.fileName, file.filePath)
                      }
                    >
                      {" "}
                      {t("deleteFile")}{" "}
                    </Button>
                  </FileRow>
                ))
              : "No file"}
            <FileRow>
              <label className="custom-file-upload">
                <input type="file" multiple onChange={onChange} />
                {t("chooseFile")}
              </label>
              <FileName> {file ? file.name : ""} </FileName>
              <FileName>
                {" "}
                <Button
                  hide={!file}
                  disabled={!file}
                  onClick={() => uploadFile(course.courseNum)}
                >
                  {" "}
                  {t("uploadFile")}{" "}
                </Button>
                <UploadSize hide={!file}> {t("uploadSize")} </UploadSize>{" "}
              </FileName>
            </FileRow>
            <HomeworkDesc>
              {t("lessonDesc")}
              <MaxWords>&nbsp; ({t("maxWords")}) </MaxWords>
            </HomeworkDesc>
            <textarea
              value={lessonDescription}
              rows={6}
              maxrows={500}
              onChange={handleChange}
            />
            <SaveFile>
              <FileName> </FileName>
              <Button onClick={() => saveFile(lessonDescription)}>
                {" "}
                {t("save")}{" "}
              </Button>
            </SaveFile>
          </FrameBorder>
        </MainBody>
        <AdsBody>
          <NavigatorBar content={false} navigatorHtml={NavigatorHtml} />
          {user ? (
            <FrameBorder>
              <Title>{t("myStudent")}</Title>
              {students
                ? students.map((student) => (
                    <Teacher key={student.studentId}>
                      <NavLink to={"/homework-th/" + student.studentId}>
                        <TeacherImg
                          src={
                            student.user.avadarImage
                              ? PATH_PREFIX_AVATAR + student.user.avadarImage
                              : Constants.StudentDefaultImg
                          }
                        />
                      </NavLink>
                      <TeacherName>
                        <NavLink to={"/homework-th/" + student.studentId}>
                          {student.firstName} {student.lastName}
                        </NavLink>
                      </TeacherName>
                    </Teacher>
                  ))
                : ""}
            </FrameBorder>
          ) : (
            ""
          )}
        </AdsBody>
        <Right />
      </Container>
    );
  }
};

export default LessonDetailTh;
