import styled from "styled-components";
import { mobile } from "../../assets/css/responsive";
import login_bg from "../../assets/images/login-bg.jpg";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.3)
    ),
    url(${login_bg});//https://images.pexels.com/photos/4559527/pexels-photo-4559527.jpeg
  background-size: cover;
  background-position: center;
  ${mobile({ height: "80vh", padding: "0" })}
`;
export const Form = styled.form`
  width: 25%;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 3% 8%;
  border-radius: 10px;
  ${mobile({ width: "50%" })}
`;
export const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: #105f38;
  margin-bottom: 10px;
`;
export const Button = styled.button`
  width: 100%;
  height: 40px;
  font-size: 13px;
  color: white;
  background-color: #105f38;
  &:hover {
    background-color: #084939;
    cursor: pointer;
  }
  padding: 10px auto;
  border-radius: 4px;
  border: none;
  margin: 10px 0px;
`;
