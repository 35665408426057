import styled from "styled-components";
import { mobile } from "../../assets/css/responsive";
import * as Constants from "../../config/Constants";
export const Container = styled.div`
  width: 100%; 
  padding-top: 30px;
  display: flex;
  ${mobile({flexWrap: 'wrap', flexDirection: 'column' })}
`;
export const Left = styled.div`
  flex: 1;
 
`;
export const MainBody = styled.div`
  flex: 6;
  margin-right: 2%;

`;
export const AdsBody = styled.div`
  flex: 2;
 

`;
export const Right = styled.div`
  flex: 1;
  
`;
export const Header = styled.div`
  text-align: left;
  font-size: 1.4rem;
  font-weight:bold;
  border: none;
  margin: 10px 10% 15px 2%;
`;
export const FrameBorder = styled.div`
  border: 1px solid #c3c3c3;
  margin-bottom: 30px;
  box-shadow: 1px 1px 4px #bbb0b0;
  border-radius: 5px;
  padding: 20px;
`;
export const Navigator = styled.div`  
border: none;
font-size: 13px;
color: gray;
margin-bottom: 2px;
`;

export const Title = styled.div`
border: none;
margin: 0px 10% 20px 10%;
${mobile({ margin: '10px 10% 10px 6%',textAlign : 'left'  })};
`;

export const Wrap = styled.div`
  display: flex; 
  justify-content: center;
  margin: 20px 10% 5px 5%;
  ${mobile({ margin: '3%'})}
`;


 export const Wrapper = styled.div`
  display: flex; 
  margin-left:  10% ;
  justify-content: center;
  flex-wrap: wrap;
 // ${mobile({display: 'block'})}
`;

export const Page = styled.div`
margin-left: 40%;
`;
export const LineWrap = styled.div`
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px 5px;
  border: 1px solid gray;
  ${mobile({height: '120px' })}
`;
export const CourseImg = styled.div`
  border: none;
  width: 25%; 
  ${mobile({width: '35%' })}
`;

export const Img = styled.img`
  border: none;
  width: 100%;
  height: 100%;
`;
export const CourseInfo = styled.div`
  border: none;
  display: flex;
  justify-content: space-around;
  width: 64%; 
  margin-top: 7%;
  ${mobile({display:'block' , margin: '2%', width: '50%'})}
`;

export const CourseName = styled.div`
  border: none;
  //width: 30%;
  font-size: 14px;
  //height: 150px;
 // margin-top: 7%;
  ${mobile({width: '80%' ,textAlign : 'left', margin: '5%'})}
`;
export const CourseDate = styled.div`
  border: none;
  font-size: 14px;
  //width: 30%;
 // height: 150px;
 //  margin-top: 7%;
   ${mobile({width: '80%', textAlign : 'left' , margin: '5%'})}
`;

export const CourseDesc = styled.p`
border: none;
margin: 0 10% 20px 10%;
font-size: 13px;
color: #999999;
`;
export const HomeworkDesc = styled.p`
border: none;
display: flex;
margin: 40px 10% 10px 10%;
${mobile({margin: '3%'})}

`;
export const CourseName2 = styled.div`
display: flex;
width: 200px;
margin-bottom: 20px;
//justify-content: space-between;
`;
export const Name = styled.div`
display: flex;
${mobile({display: 'block'})};
margin-bottom: 20px;
//justify-content: space-between;
`;

export const DateDisplay = styled.div`
display: flex;
font-size: 13px;
color: #999999;
justify-content: space-between;

${mobile({display:'block',textAlign : 'left' })}
`;

export const Teacher = styled.div`
border: none;
margin: 20px 10% 20px 10%;
display: flex;
justify-content: space-between;
`;
export const TeacherImg = styled.img`
border: none;
border-radius: 50%;
width: 88px;
height: 88px;
&:hover {
  cursor: pointer;
  opacity: 0.8;
}
`;

export const TeacherName = styled.div`
  width: 50%;
  font-size: 14px;
  color: #999999;
  height: 88px;
  line-height: 88px;
`

export const LessonName = styled.div`
  border: none;
   
  font-size: 13px;
  color: #666666;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;


export const Status = styled.div`
  border: none;
  width: 15%;
  height: 150px;
  line-height: 150px;
  margin: 15px 0px;
`;

export const Label = styled.div`
  box-sizing: border-box;
  width: 140px;
  height: 40px;
  margin: 5px;
  padding: 10px;
  text-align: center;
  background-color: ${props => props.isClicked ? '#fff' : '#eee'};
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
export const Ads = styled.img`
  border: none;
  width: 80%;
  height: 380px;
`;
export const ImgBlock = styled.img`
  margin: 15px 3px;
  height: 25px;
  width: 25px;
  box-sizing: border-box;
  display: inline-block;
`;
export const ErrorMsg = styled.div`
  font-size: 15px;
  color: #f30808;
`;

export const Button = styled.button`
  font-size: 13px;
  color: ${Constants.ButtonColor};
  width: 135px;
  height: 33px;
  border: 1px solid #b3a9a9;
  border-radius: 20px;
  cursor: pointer;
  background:  ${Constants.ButtonBackgroundColor};
  &:hover {
    background: ${Constants.ButtonHoverBackgroundColor};
  }; 
 
  display: ${(props) => (props.hide ? "none" : "block")};
`;
