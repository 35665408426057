import * as Constants from "../../config/Constants";
import { useTranslation } from "react-i18next"
import {
  FrameBorder,
  Title,
  Name,
  Teacher,
  TeacherImg,
  TeacherName,
} from "./Mainstyle";
;

const StudentDisplay = ({ students, role }) => {
  const { t, i18n } = useTranslation();

  const EDU_STORAGE_URL = process.env.REACT_APP_EDU_STORAGE_URL;
  const PATH_PREFIX_AVATAR = EDU_STORAGE_URL + Constants.PATH_PREFIX_DEFAULT;

  return (
    <FrameBorder>
      <Title>{t(role)}</Title>
      {students
        ? students.map((student) => (
            <Teacher key={student.studentId}>
              <TeacherImg
                src={
                  student.user.avadarImage
                    ? PATH_PREFIX_AVATAR + student.user.avadarImage
                    : Constants.StudentDefaultImg
                }
              />
              <TeacherName>
                {student.firstName} {student.lastName}
              </TeacherName>
            </Teacher>
          ))
        : ""}
    </FrameBorder>
  );
};

export default StudentDisplay;
