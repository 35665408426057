import { createSlice } from "@reduxjs/toolkit";

export const courseSlice = createSlice({
  name: "course",
  initialState: { course: null },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCourse: (state, action) => {
      state.value = action.payload;
    },
    clearCourse: (state) => {
      state.value = null;
    },
  },
});

export const { setCourse, clearCourse } = courseSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCourse = (state) => state.course.value;

export default courseSlice.reducer;
