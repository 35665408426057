import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const NavigatorBar = (props) => {
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    navigate(event.target);
  };

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        {props.content ? (
          <props.navigatorHtml />
        ) : (
          <Link underline="none">&nbsp; </Link>
        )}
      </Breadcrumbs>
    </div>
  );
};

export default NavigatorBar;
