import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import FormInput from "../components/common/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/userSlice";
import { selectLanguage } from "../store/languageSlice";
import { useNavigate } from "react-router-dom";
import * as Constants from "../config/Constants";
import {
  Container,
  Form,
  Title,
  Button,
} from "../components/common/LoginRegister";

const ForgotPass = styled.div`
  font-size: 12px;
  color: #105f38;
  text-align: left;
  margin-top: 10px;
`;
const NavToRegister = styled.div`
  font-size: 11px;
  color: #105f38;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
`;
const Word = styled.div`
  margin: 0px 5px;
`;
const Error = styled.div`
  font-size: 12px;
  color: ${(props) => (props.success ? "green" : "red")};
  margin-left: 10px;
  display: block;
  text-align: left;
`;

const Login = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const getLanguage = useSelector(selectLanguage);
  const dispatch = useDispatch();

  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;
  //const EDU_ORG_ID = process.env.REACT_APP_EDU_ORG_ID;

  const initRegInfo = {
    //organization: { orgId: EDU_ORG_ID },
    email: "",
    password: "",
    pass2: "",
    validateErr: "",
  };

  const [posted, setPosted] = useState(false);
  const [errors, setErrors] = useState([]);
  const [regInfo, setRegInfo] = useState(initRegInfo);
  const [user, setUser] = useState(null);
  
  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      label: "Email",
      pattern:
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
      validateErr: "invalid email format",
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Password",
      label: "Password",
      pattern: "^[a-zA-Z0-9!@#$%^&*()_+=?]{8,16}$",
      validateErr: "password is between 8-16 characters",
    },
  ];

  const callSignInApi = () => {
    const axiosConfig = {...Constants.AXIOS_CONFIG_BASE};
    axiosConfig.headers["x-token-subject"] = regInfo.email
    // console.log("hello here", axiosConfig);
    // console.log("input", regInfo);
    axios
      .post(
        EDU_API_BASE_URL + "auth-edu/signin",
        regInfo,
        axiosConfig
      )
      .then((response) => {
        console.log("userId", response.data.data[0].userId);
        setUser(response.data.data[0].userId);
        const userRoles = response.data.data[0].roles.map(role => role.roleName);
        if (response.data.data[0].roles[0].roleName === "STUDENT") {
          //callStudentInfo(response.data.data[0].userId)
          dispatch(login(response.data.data[0]));
          if (response.data.data[0].student) {
            navigate("/student-course");
          } else {
            navigate("/student-info");
          }
        } else if (userRoles.includes("TEACHER")) {
          //callTeacherInfo(response.data.data[0].userId)
          dispatch(login(response.data.data[0]));
          navigate("/teacher-course");
        }
        setErrors([{ message: { user } + "Your have logged in successfully" }]);
      })
      .then(() => {
        setPosted(true);
        setErrors([{ message: { user } + "Your have logged in successfully" }]);
        console.log("user", user);
      })
      .catch((error) => {
        setUser(null);
        setPosted(false);
        setErrors([{ message: error.message }]);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.email.value === "" || e.target.password.value === "") {
      setPosted(false);
      setErrors([{ message: "Don't leave fields empty" }]);
      return;
    }
    callSignInApi();
  };

  const onChange = (e) => {
    setRegInfo({ ...regInfo, [e.target.name]: e.target.value });
    console.log(regInfo);
  };

  useEffect(() => i18n.changeLanguage(getLanguage), []);

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Title> {t("login")}</Title>
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={regInfo[input.name]}
            onChange={onChange}
          />
        ))}
        <ForgotPass>
          <NavLink to="/forgotpass">{t("forget")} ?</NavLink>
        </ForgotPass>
        <Button type="submit"> {t("login")}</Button>
        <NavToRegister>
          <Word>{t("noAccount")}?</Word>
          <NavLink to="/register"> {t("register")}</NavLink>
        </NavToRegister>
        {errors.map((error) => (
          <Error key={error.message} success={posted}>
            {error.message}
          </Error>
        ))}
      </Form>
    </Container>
  );
};

export default Login;
