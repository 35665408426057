import React from "react";

import HomeSlide from "./../components/HomeSlide";
import HomeCourseList from "./../components/HomeCourseList";

const Home = () => {
  return (
    <div>
      <HomeSlide />
      <HomeCourseList />
    </div>
  );
};

export default Home;
