import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { mobile } from "./../assets/css/responsive";
import * as Constants from "../config/Constants";
import { selectUser } from "../store/userSlice";
import { useSelector } from "react-redux";

const Container = styled.div`
  height: 40px;
  background-color: ${Constants.NavigationBackgroundColor};
  ${mobile({ display: "none" })}
`;
const Wrapper = styled.div`
  margin-inline: auto;
  width: 80%;
  height: 100%;
  display: flex;
`;
const Left = styled.div`
  flex: 5;
  display: flex;
`;
const Center = styled.div`
  flex: 1;
`;
const Right = styled.div`
  flex: 2;
`;
const NavLink = styled.a`
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 12px;
  font-size: 13px;
  color: white;
  text-decoration: none;
  line-height: 15px;
`;
const Welcome = styled.div`
  justify-content: right;
  font-size: 13px;
  color: #ffffff;
  margin-top: 12px;
`;

const NavBar = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <Left>
          <NavLink href="/home">{t("home")}</NavLink>
          <NavLink href="/about">{t("about_us")}</NavLink>
          <NavLink href="/contact">{t("contact_us")}</NavLink>
        </Left>
        <Center></Center>
        <Right>
          <Welcome>
            {user ? t("welcome").replace("{userName}", user.username) : ""}
          </Welcome>
        </Right>
      </Wrapper>
    </Container>
  );
};

export default NavBar;
