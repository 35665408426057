import React from "react";
import styled from "styled-components";
import formInput from "../../assets/css/formInput.css";



const FormInput = (props) => {
  const {id, label, onChange, validateErr, ...others} = props;
  return (
    <div>
     
      <input {...others} onChange={onChange} />      
     
      <span className="error">{validateErr}</span>
    </div>
  );
};

export default FormInput;
