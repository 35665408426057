import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { Form, Field } from "react-final-form";
import { Button } from "../components/common/LoginRegister";
import { useDispatch, useSelector } from "react-redux";
import { login, selectUser } from "../store/userSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Constants from "../config/Constants";

const Styles = styled.div`
font-family: sans-serif;
h1 {
  text-align: center;
  color: #222;
  margin-bottom: 0;
}
h2 {
  text-align: center;
  color: #222;
}
h3 {
  text-align: center;
  color: #666;
  margin: 0;
}
form {
  text-align: center;
  width: 80%;
  margin: 10px auto;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  position: relative;
  & > h1 {
    margin: 20px auto;
  }
  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 20em;
    height: 4em;
    align-items: top;
    position: relative;
    padding: 5px 3px;
    border: 1px solid transparent;
    &.active {
      background-color: paleturquoise;
      border-color: turquoise;
    }
    & > label {
      color: #333;
      min-width: 5em;
      height: 2em;
      font-size: 0.8em;
      line-height: 2em;
      text-align: center;
      vertical-align: center;
      padding: 4px 2px;
    }
    & > textarea {
      font-family: monospace, monospace;
      border: 1px solid #ccc;
      border-radius: 3px;
      width: 14em;
      height: 7em;
      margin-left: 3px;
      text-indent: 4px;
    }
    & > input,
    & > select {
      font-family: monospace, monospace;
      padding: 3px 2px;
      width: 14em;
      height: 2em;
      line-height: 2em;
      font-size: 0.8em;
      margin: 0 5px;
      text-indent: 4px;
      border: 1px solid #ccc;
      border-radius: 3px;
      &[disabled] {
        background: #eee;
      }
    }
    & > span {
      display: block;
      width: 20em;
      height: 2em;
      font-size: 0.8em;
      line-height: 1.4em;
      text-indent: 8em;
      color: #800;
      font-weight: bold;
    }
  }
}
.inputRadio {
  & > div {
    height: 1.4em;
    font-size: 0.8em;
    line-height: 1.4em;
    text-align: center;
    vertical-align: center;
    padding: 6px 2px;
    & > label {
      margin-right: 16px;
      & > input {
        width: 12px;
        height: 12px;
        margin: 3px 5px;
      }
    }
  }
}
button {
  margin: 70px auto 10px;
  &[type="submit"] {
    display: block;
    width: 20em;
    height: 4em;
    border-radius: 5px;
  }
}
`;
const Error = styled.div`
  font-size: 12px;
  color: ${(props) => (props.success ? "green" : "red")};
  margin-left: 10px;
  display: block;
  text-align: left;
`;

const StudentInfo = () => {
  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [posted, setPosted] = useState(false);
  const [errors, setErrors] = useState([]);
  const user = Object.assign({}, useSelector(selectUser));
  const AXIOS_CONFIG = {...Constants.AXIOS_CONFIG_BASE};
  AXIOS_CONFIG.headers["x-token-subject"] = user.email;

  const userId = user ? user.userId : "";
  const required = (value) => (value ? undefined : "required");

  const submitResults = (value) => {
    callStudentApi(value);
  };
  
  const callStudentApi = (value) => {
    value.user = { userId };
    console.log("value", value);
    axios
      .post(
        EDU_API_BASE_URL + "user/student",
        value,
        AXIOS_CONFIG
      )
      .then((response) => {
        console.log(response);
        user.student = response.data.data[0];
        dispatch(login(user));
        setPosted(true);
        setErrors([{ message: "Submit student info successfully" }]);
        navigate("/student-course");
      })
      .catch((error) => {
        console.log(error);
        setPosted(false);
        if (error.response && (error.response.status = 400)) {
          setErrors(error.response.data.notifications);
        } else {
          setErrors([{ message: error.message }]);
        }
      });
  };

  useEffect(() => {
    if (!user) navigate("/login");
    if (user.student) navigate("/student-course");
  }, [user]);

  return (
    <Styles>
      <div>
        <Form onSubmit={submitResults} subscription={{ submitting: true }}>
          {({ handleSubmit, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              <h1>{t("studentInfo")}</h1>

              <div>
                {/* field values start */}
                {/* student_num */}
                {/*   <div>
                  <label>{t('studentNum')}</label>
                  <Field
                    name="studentNum"
                    component="input"
                    placeholder="学号"
                  />
                </div> */}
                {/* last_name */}
                <Field
                  name="lastName"
                  placeholder={t("lastName")}
                  validate={required}
                >
                  {({ input, meta, placeholder }) => (
                    <div>
                      <label>{t("lastName")}</label>
                      <input {...input} placeholder={placeholder} />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
                {/* first_name */}
                <Field
                  name="firstName"
                  placeholder={t("firstName")}
                  validate={required}
                >
                  {({ input, meta, placeholder }) => (
                    <div>
                      <label>{t("firstName")}</label>
                      <input {...input} placeholder={placeholder} />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
                {/* gender */}
                <div className="inputRadio">
                  <label>{t("gender")}</label>
                  <div>
                    <label>
                      <Field
                        name="gender"
                        component="input"
                        type="radio"
                        value="1"
                      />
                      {t("male")}
                    </label>
                    <label>
                      <Field
                        name="gender"
                        component="input"
                        type="radio"
                        value="2"
                      />
                      {t("female")}
                    </label>
                    <label>
                      <Field
                        name="gender"
                        component="input"
                        type="radio"
                        value="3"
                      />
                      {t("other")}
                    </label>
                  </div>
                </div>
                {/* birth_date */}
                <div>
                  <label>{t("birthDate")}</label>
                  <Field name="birthDate" component="input" type="date" />
                </div>
                {/* cell_phone */}
                <div>
                  <label>{t("cellPhone")}</label>
                  <Field
                    name="cellPhone"
                    component="input"
                    placeholder={t("cellPhone")}
                  />
                </div>
                {/* billing_address */}
                <div>
                  <label>{t("billingAddress")}</label>
                  <Field
                    name="billingAddress"
                    component="input"
                    placeholder={t("billingAddress")}
                  />
                </div>
                {/* city */}
                <div>
                  <label>{t("city")}</label>
                  <Field
                    name="city"
                    component="input"
                    placeholder={t("city")}
                  />
                </div>
                {/* province */}
                <div>
                  <label>{t("province")}</label>
                  <Field
                    name="province"
                    component="input"
                    placeholder={t("province")}
                  />
                </div>
                {/* postal_code */}
                <div>
                  <label>{t("postalCode")}</label>
                  <Field
                    name="postalCode"
                    component="input"
                    placeholder={t("postalCode")}
                  />
                </div>
                {/* country */}
                <div>
                  <label>{t("country")}</label>
                  <Field
                    name="country"
                    component="input"
                    placeholder={t("country")}
                  />
                </div>
                {/* description */}
                <div>
                  <label>{t("description")}</label>
                  <Field
                    name="description"
                    component="textarea"
                    placeholder={t("description")}
                  />
                </div>
                {/* field values end */}
              </div>
              <Button type="submit">{t("save")}</Button>
              {errors.map((error) => (
                <Error key={error.message} success={posted}>
                  {error.message}
                </Error>
              ))}
            </form>
          )}
        </Form>
      </div>
    </Styles>
  );
};

export default StudentInfo;
