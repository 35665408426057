import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { selectLanguage } from "../store/languageSlice";
import { Container } from "../components/common/LoginRegister";
import { useSelector } from "react-redux";
import * as Constants from "../config/Constants";

const Error = styled.div`
  font-size: 12px;
  color: ${(props) => (props.success ? "green" : "red")};
  margin-left: 10px;
  display: block;
  text-align: left;
`;

const Logout = () => {
  const { t, i18n } = useTranslation();
  const getLanguage = useSelector(selectLanguage);
  const [user, setUser] = useState(null);

  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;
  const EDU_ORG_ID = process.env.REACT_APP_EDU_ORG_ID;
  const AXIOS_CONFIG = {...Constants.AXIOS_CONFIG_BASE};
  AXIOS_CONFIG.headers["x-token-subject"] = user.email;

  const initRegInfo = {
    orgId: EDU_ORG_ID,
    campusId: 1,
    userName: "",
    email: "",
    userPwd: "",
    pass2: "",
    validateErr: "",
    roles: [
      {
        roleName: "STUDENT",
      },
    ],
  };

  const [posted, setPosted] = useState(false);
  const [errors, setErrors] = useState([]);
  const [regInfo, setRegInfo] = useState(initRegInfo);

  const callLogoutApi = () => {
    console.log("input", regInfo);
    axios
      .post(
        EDU_API_BASE_URL + "user/logout",
        regInfo,
        AXIOS_CONFIG
      )
      .then((response) => {
        console.log("logout-res", response.data.data[0]);
        setUser(response.data.data[0].userId);
        setErrors([
          { message: { user } + "Your have logged out successfully" },
        ]);
        window.location = "/home";
      })
      .catch((error) => {
        console.log("logout-error", error.message);
        setUser(null);
        setPosted(false);
        setErrors([{ message: error.message }]);
      });
  };

  useEffect(() => {
    callLogoutApi();
    i18n.changeLanguage(getLanguage);
  }, []);

  return (
    <Container>
      {errors.map((error) => (
        <Error key={error.message} success={posted}>
          {error.message}
        </Error>
      ))}
    </Container>
  );
};

export default Logout;
