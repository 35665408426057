import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Constants from "../config/Constants";
import { useSelector } from "react-redux";
import Message from "../components/common/Message";
import { selectLanguage } from "../store/languageSlice";
import { selectCourse } from "../store/courseSlice";
import { selectLesson } from "../store/lessonSlice";
import { selectUser } from "../store/userSlice";
import { selectClassmate } from "../store/classmateSlice";
import "../assets/css/formInput.css";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import NavigatorBar from "../components/common/NavigatorBar";
import { mobile } from "../assets/css/responsive";
import DateList from "../components/common/DateList";
import {
  Container,
  Left,
  MainBody,
  AdsBody,
  Right,
  FrameBorder,
  Navigator,
  Title,
  Name,
  Teacher,
  TeacherImg,
  TeacherName,
  Button,
  HomeworkDesc,
} from "../components/common/Mainstyle";

const MaxWords = styled.p`
  font-size: 11px;
  color: #666666;
  height: 21px;
  line-height: 21px;
`;
const FileRow = styled.div`
  margin: 0 10% 2% 10%;
  display: flex;
  justify-content: space-between;
  ${mobile({ margin: "10px 10% 10px 6%" })};
`;
const SaveFile = styled.div`
  display: flex;
  margin: 3% 10% 3% 10%;
  justify-content: space-between;
`;
const FileName = styled.div`
  border: none;
  height: 33px;
  padding-top: 5px;
  font-size: 13px;
  color: #666666;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
const FileImg = styled.div`
  border: none;
  width: 25%;
`;
const Img = styled.img`
  border: 1px solid gray;
  width: 100%;
  height: 100%;
`;
const Allow = styled.div`
  display: inline;
  font-size: 13px;
  margin-right: 3px;
`;
const Profile = styled.div`
  background-color: #ebf2eb;
  text-align: center;
  width: 100px;
  margin-left: 2%;
  margin-bottom: 2%;
  padding: 2%;
  border-radius: 6%;
  float: right;
`;
const Info = styled.div`
  font-size: 11px;
  color: #6363a2ac;
  text-align: center;
  margin: 3%;
`;

const MyHomeworkTh = () => {
  const getLanguage = useSelector(selectLanguage);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const course = useSelector(selectCourse);
  const lesson = useSelector(selectLesson);
  const lessonId = lesson ? lesson.lessonId : "";
  const students = useSelector(selectClassmate);
  const { studentId } = useParams();

  const today = new Date();
  const year = "" + today.getFullYear();
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  //const yearMonth = year.substring(2) + month;

  const [homeworkFiles, setHomeworkFiles] = useState([]);
  const [homeworkData, setHomeworkData] = useState({});
  const [homeworkTcomments, setHomeworkTcomments] = useState("");
  const [homeworkComfirm, setHomeworkComfirm] = useState(false);
  
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState("");
  const [message, setMesasge] = useState("");
  const [show, setShow] = useState(false);
  const [studentInfo, setStudentInfo] = useState({});
  const [pic, setPic] = useState(null);
    
  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;
  const EDU_STORAGE_URL = process.env.REACT_APP_EDU_STORAGE_URL;
  const AXIOS_CONFIG = {...Constants.AXIOS_CONFIG_BASE};
  AXIOS_CONFIG.headers["x-token-subject"] = user.email;
  const PATH_PREFIX_AVATAR = EDU_STORAGE_URL + Constants.PATH_PREFIX_DEFAULT;

  const NavigatorHtml = () => {
    return (
      <Navigator>
        <Link underline="hover" color="inherit" href="/">
          {t("home_breadcrumb")}/
        </Link>
        <Link underline="hover" color="inherit" href="/teacher-course/">
          {t("teacherCourse")}/
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href={"/course-detail/" + course.courseId}
        >
          {t("course_detail")}/
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href={"/lesson-detail-th/" + lesson.lessonId}
        >
          {t("lesson_detail")}/
        </Link>
        <Link underline="none" color="text.primary" aria-current="page">
          {t("myHomework")}
        </Link>
      </Navigator>
    );
  };

  const fetchStudentBystudentId = () => {
    for (let i = 0; i < students.length; i++) {
      if (students[i].studentId == studentId) {
        setStudentInfo(students[i]);
        console.log(students[i]);
        break;
      }
    }
  };

  const fetchHomeworkFilesByhomeworkId = (homeworkId) => {
    axios
      .get(
        EDU_API_BASE_URL + "homework/" + homeworkId + "/files",
        AXIOS_CONFIG
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setHomeworkFiles(response.data.data);
          console.log("homework-files-res", response.data.data);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchHomeworkFilesByhomeworkId"]);
        console.log("homework-files-error", error);
      });
  };
  const fetchHomeworkByLessonIdStudentId = () => {
    axios
      .get(
        EDU_API_BASE_URL + "homework/all/" + lessonId + "/" + studentId,
        AXIOS_CONFIG
      )
      .then((response) => {
        if (response.data.data.length <= 0) {
          alert(t("noHomeworkAlert"))
          navigate("/lesson-detail-th/"+lessonId)
        }
        else if (response.status >= 200 && response.status < 299) {
          setHomeworkData(response.data.data[0]);
          setHomeworkComfirm(response.data.data[0].teacherConfirmed);
          setHomeworkTcomments(response.data.data[0].tcomments);
          fetchHomeworkFilesByhomeworkId(response.data.data[0].homeworkId);
          console.log("homework-all-res", response.data.data);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([
          ...errors,
          error.message + ": fetchHomeworkByLessonIdStudentId",
        ]);
        console.log("homework-all-error", error);
      });
  };

  const saveFile = (homeworkTcomments, homeworkComfirm) => {
    const value = {
      tcomments: homeworkTcomments,
      teacherConfirmed: homeworkComfirm,
      homeworkId: homeworkData.homeworkId,
      lessonId: homeworkData.lessonId,
      studentId: homeworkData.studentId,
      teacherId: homeworkData.teacherId,
      description: homeworkData.description,
    };

    axios
      .post(EDU_API_BASE_URL + "homework", value, AXIOS_CONFIG)
      .then((response) => {
        console.log("homework-post-res", response.data);
        setMesasge(t("saveSuccess"));
        setError("");
        setShow(true);
        const timeId = () =>
          setTimeout(() => {
            setShow(false);
          }, 5000);
        timeId();
        fetchHomeworkByLessonIdStudentId();
      })
      .catch((error) => {
        setMesasge(t("saveError"));
        setError(error);
        setShow(true);
        const timeId = () =>
          setTimeout(() => {
            setShow(false);
          }, 5000);
        timeId();
        console.log("homework-post-error", error);
      });
  };

  useEffect(() => {
    i18n.changeLanguage(getLanguage);
    if (!user) navigate("/login");

    if (errors.length === 0) {
      fetchStudentBystudentId();
      fetchHomeworkByLessonIdStudentId();
    }
  }, [studentId]);

  const comfirmChange = () => {
    setHomeworkComfirm(!homeworkComfirm);
  };

  const handleChange = (e) => {
    setHomeworkTcomments(e.target.value);
  };

  if (errors.length > 0) {
    return (
      <div>
        {errors.map((error) => (
          <div>{error}</div>
        ))}
      </div>
    );
  } else {
    return (
      <Container>
        <Left />
        <MainBody>
          {show ? <Message message={message} error={error} /> : ""}
          <NavigatorBar content={true} navigatorHtml={NavigatorHtml} />
          <FrameBorder>
            {course ? (
              <Title>
                <Name>
                  {t("check-homework")} ({course.courseName})
                </Name>
                <Profile>
                  <TeacherImg
                    src={
                      studentInfo.user
                        ? studentInfo.user.avadarImage
                          ? PATH_PREFIX_AVATAR + studentInfo.user.avadarImage
                          : Constants.StudentDefaultImg
                        : ""
                    }
                  />
                  <Info>{studentInfo.studentNum}</Info>
                  <Info>
                    {studentInfo.firstName} {studentInfo.lastName}
                  </Info>
                </Profile>
                <DateList lesson={lesson} />
              </Title>
            ) : (
              ""
            )}
            {homeworkFiles
              ? homeworkFiles.map((file) => (
                  <FileRow key={file.fileId}>
                    <a
                      href={
                        EDU_STORAGE_URL + file.filePath + "/" + file.fileName
                      }
                      target="_blank"
                      onMouseOver={() => setPic(file.fileId)}
                      onMouseLeave={() => setPic(null)}
                      download
                    >
                      <FileName>{file.fileName} </FileName>
                    </a>
                    {pic === file.fileId &&
                    (file.fileType === "jpg" || file.fileType === "png") ? (
                      <FileImg>
                        <Img
                          src={
                            EDU_STORAGE_URL +
                            file.filePath +
                            "/" +
                            file.fileName
                          }
                        />
                      </FileImg>
                    ) : (
                      ""
                    )}
                  </FileRow>
                ))
              : ""}
            <HomeworkDesc>
              {t("homeworkDesc")}
              <MaxWords>&nbsp; ({t("maxWords")}) </MaxWords>
            </HomeworkDesc>
            <textarea
              value={homeworkData.description ? homeworkData.description : ""}
              rows={6}
              maxrows={500}
              readOnly={true}
            />
            <HomeworkDesc>{t("teacherComment")}</HomeworkDesc>
            <textarea
              value={homeworkTcomments}
              rows={6}
              maxrows={500}
              onChange={handleChange}
            />
            <SaveFile>
              <label>
                <Allow>{t("allow")}</Allow>
                <input
                  type="checkbox"
                  onChange={comfirmChange}
                  checked={homeworkComfirm}
                />
              </label>
              <Button
                hide={false}
                onClick={() => saveFile(homeworkTcomments, homeworkComfirm)}
              >
                {" "}
                {t("save")}{" "}
              </Button>
            </SaveFile>
          </FrameBorder>
        </MainBody>
        <AdsBody>
          <NavigatorBar content={false} navigatorHtml={NavigatorHtml} />
          {user ? (
            <FrameBorder>
              <Title>{t("myStudent")}</Title>
              {students
                ? students.map((student) => (
                    <Teacher key={student.studentId}>
                      <NavLink to={"/homework-th/" + student.studentId}>
                        <TeacherImg
                          src={
                            student.user.avadarImage
                              ? PATH_PREFIX_AVATAR + student.user.avadarImage
                              : Constants.StudentDefaultImg
                          }
                        />
                      </NavLink>
                      <TeacherName>
                        <NavLink to={"/homework-th/" + student.studentId}>
                          {student.firstName} {student.lastName}
                        </NavLink>
                      </TeacherName>
                    </Teacher>
                  ))
                : ""}
            </FrameBorder>
          ) : (
            ""
          )}
        </AdsBody>
        <Right />
      </Container>
    );
  }
};

export default MyHomeworkTh;
