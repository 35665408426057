import teacherWomanDefaultImg from "./../assets/images/woman-default.png";
import teacherManDefaultImg from "./../assets/images/man-default.png";
import studentDefaultImg from "./../assets/images/student-default.png";
import couseDefaultImg from "./../assets/images/course-default.png";
import adsImg_1 from "./../assets/images/ads-1.jpg";
import giftImg_1 from "./../assets/images/gift.jpg";

//image
export const TeacherWomanDefaultImg = teacherWomanDefaultImg;
export const TeacherManDefaultImg = teacherManDefaultImg;
export const StudentDefaultImg = studentDefaultImg;
export const CourseDefaultImg = couseDefaultImg;
export const AdsImg_1 = adsImg_1;
export const GiftImg_1 = giftImg_1;

//path
export const PATH_PREFIX_PREVIEW = "upload/sys/$ORG_NUM/preview/".replace("$ORG_NUM", process.env.REACT_APP_EDU_ORG_NUM);
export const PATH_PREFIX_DEFAULT = "upload/sys/$ORG_NUM/default/".replace("$ORG_NUM", process.env.REACT_APP_EDU_ORG_NUM);

//api header
export const AXIOS_CONFIG_BASE = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8;",
    Accept: "application/json",
    //"Authorization": "Bearer",
    // "x-token-subject": "student1@lnova.com",
    //"x-org-id": process.env.REACT_APP_EDU_ORG_ID,
    "x-channel": "WEB_EDU",
    "x-lang": "en",
  },
};

//TODO: go env
export const ButtonColor = "white";
export const ButtonHoverBackgroundColor = "#147c5d";
export const ButtonBackgroundColor = "#15887c";
export const HeaderBackgroundColor = "#15887c";
export const NavigationBackgroundColor = "#147c5d";
export const FooterBackgroundColor = "#171f1e";
