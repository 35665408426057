import { createSlice } from "@reduxjs/toolkit";

export const classmateSlice = createSlice({
  name: "classmate",
  initialState: { classmate: null },
  reducers: {
    setClassmate: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setClassmate } = classmateSlice.actions;
export const selectClassmate = (state) => state.classmate.value;

export default classmateSlice.reducer;
