import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Constants from "../config/Constants";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage } from "../store/languageSlice";
import { selectUser } from "../store/userSlice";
import { selectCourse } from "../store/courseSlice";
import { setLesson } from "../store/lessonSlice";
import { selectTeacher } from "../store/teacherSlice";
import { selectClassmate } from "../store/classmateSlice";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import NavigatorBar from "../components/common/NavigatorBar";
import TeacherDisplay from "../components/common/TeacherDisplay";
import StudentDisplay from "../components/common/StudentDisplay";
import DateList from "../components/common/DateList";
import {
  Container,
  Left,
  MainBody,
  AdsBody,
  Right,
  FrameBorder,
  Navigator,
  Wrap,
  Title,
  Img,
  CourseDesc,
  Name,
  Button,
} from "../components/common/Mainstyle";

const LessonDetail = () => {
  const getLanguage = useSelector(selectLanguage);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { lessonId } = useParams();
  const myref = useRef();
  const user = useSelector(selectUser);
  const teachers = useSelector(selectTeacher);
  const classmates = useSelector(selectClassmate);
  const course = useSelector(selectCourse);
  const courseId = course ? course.courseId : "";
  const studentId = user ? user.student.studentId : "";

  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;
  const EDU_STORAGE_URL = process.env.REACT_APP_EDU_STORAGE_URL;
  const AXIOS_CONFIG = {...Constants.AXIOS_CONFIG_BASE};
  AXIOS_CONFIG.headers["x-token-subject"] = user.email;  

  const [homeworkData, setHomeworkData] = useState({});
  const [lessonData, setLessonData] = useState({});
  const [lessonFiles, setLessonFiles] = useState([]);
  const [fileCount, setFileCount] = useState(0);
  const [errors, setErrors] = useState([]);
  
  const NavigatorHtml = () => {
    return (
      <Navigator>
        <Link underline="hover" color="inherit" href="/">
          {t("home_breadcrumb")}
        </Link>
        /
        {user && (
          <Link underline="hover" color="inherit" href="/student-course/">
            {t("studentCourse")}
          </Link>
        )}
        /
        <Link
          underline="hover"
          color="inherit"
          href={"/course-detail/" + courseId}
        >
          {t("course_detail")}
        </Link>
        /
        <Link underline="none" color="text.primary" aria-current="page">
          {t("lesson_detail")}
        </Link>
      </Navigator>
    );
  };

  const fetchHomeworkByLessonId = async () => {
    axios
      .get(EDU_API_BASE_URL + "homework/all/" + lessonId + "/" + studentId, AXIOS_CONFIG)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setHomeworkData(response.data.data[0]);
          console.log("homework-all-res", response.data.data);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchHomeworkByLessonId"]);
        console.log(error);
      });
  };
  const fetchLessonByLessonId = async () => {
    axios
      .get(EDU_API_BASE_URL + "lesson/" + lessonId, AXIOS_CONFIG)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setLessonData(response.data.data[0]);
          dispatch(setLesson(response.data.data[0]));
          console.log("lesson-by-id-res", response.data.data[0].lessonName);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchLessonByLessonId"]);
        console.log(error);
      });
  };

  const fetchLessonFilesByLessonId = async () => {
    axios
      .get(EDU_API_BASE_URL + "lesson/" + lessonId + "/files", AXIOS_CONFIG)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setFileCount(response.data.data.length);
          setLessonFiles(response.data.data);
          console.log("lesson-files-by-lid-res", response.data.data);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchLessonFilesByLessonId"]);
        console.log(error);
      });
  };

  const postHomeworkByLessonId = async () => {
    const value = { description: "", lessonId: lessonId, studentId: studentId };
    axios
      .post(EDU_API_BASE_URL + "homework", value, AXIOS_CONFIG)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setHomeworkData(response.data.data[0]);
          console.log("homework-post-res", response.data.data[0]);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": postHomework"]);
        console.log(error);
      });
  };

  const createHomework = () => {
    postHomeworkByLessonId();
  };
  const doHomework = (homeworkId) => {
    navigate("/homework-st/" + homeworkId);
  };

  useEffect(() => {
    i18n.changeLanguage(getLanguage);
    if (!user) navigate("/login");

    if (course) {
      if (user && errors.length === 0) fetchHomeworkByLessonId();
      if (errors.length === 0) {
        fetchLessonByLessonId();
        fetchLessonFilesByLessonId();
      }
    }
  }, [fileCount]);

  if (errors.length > 0) {
    return (
      <div>
        {errors.map((error) => (
          <div>{error}</div>
        ))}
      </div>
    );
  } else {
    return (
      <Container>
        <Left />
        <MainBody>
          <NavigatorBar content={true} navigatorHtml={NavigatorHtml} />
          <FrameBorder>
            {course ? (
              <Title>
                <Name>
                  {t("lesson-prepare")} ( {course.courseName} )
                </Name>
                <DateList lesson={lessonData} />
              </Title>
            ) : (
              ""
            )}

            {lessonFiles
              ? lessonFiles.map((file) =>
                  file.fileType === "jpg" || file.fileType === "png" ? (
                    <Img
                      alt="lesson img"
                      key={file.fileId}
                      src={EDU_STORAGE_URL + file.filePath + "/" + file.fileName}
                    />
                  ) : (
                    <a
                      href={EDU_STORAGE_URL + file.filePath + "/" + file.fileName}
                      target="_blank"
                      download
                    >
                      {file.fileName}
                    </a>
                  )
                )
              : ""}
            <br />
            <br />
            <Title>{t("lesson_detail")}</Title>
            <CourseDesc>{lessonData.description}</CourseDesc>
          </FrameBorder>
        </MainBody>
        <AdsBody>
          <NavigatorBar content={false} navigatorHtml={NavigatorHtml} />
          {user ? (
            <FrameBorder>
              <Title ref={myref}>{t("myHomework")}</Title>
              {homeworkData ? (
                <Wrap>
                  <Button
                    hide={false}
                    onClick={() => doHomework(homeworkData.homeworkId)}
                  >
                    {" "}
                    {t("doHomework")}{" "}
                  </Button>
                </Wrap>
              ) : (
                <Wrap>
                  <Button hide={false} onClick={createHomework}>
                    {" "}
                    {t("createHomework")}{" "}
                  </Button>
                </Wrap>
              )}
            </FrameBorder>
          ) : (
            ""
          )}
          <TeacherDisplay teachers={teachers} role={"myTeacher"} />
          {user ? (
            <StudentDisplay students={classmates} role={"myClassmate"} />
          ) : (
            ""
          )}
        </AdsBody>
        <Right />
      </Container>
    );
  }
};

export default LessonDetail;
