import React, { useEffect, useState } from 'react'
import './ProfileCardStyles.css'
import axios from "axios"
import { FaCamera,FaUserAlt,FaDollarSign,FaSignOutAlt,FaKey } from "react-icons/fa"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser } from "../../store/userSlice";
import { useNavigate } from "react-router-dom";
import * as Constants from "../../config/Constants";


 const ProfileCard = ({ students, role })  => {
    const EDU_STORAGE_URL = process.env.REACT_APP_EDU_STORAGE_URL;
    const PATH_PREFIX_AVATAR = EDU_STORAGE_URL + Constants.PATH_PREFIX_DEFAULT;
  const [profileImage,setProfileImage] = useState("")
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const profileData = async () => {
    try{
        const res = await axios.get("http://randomuser.me/api");
        setProfileImage(res.data.results[0].picture.large);

    }catch (error){
        console.log(error);
    }
  };
  
  useEffect(() => {
    profileData();
  },[]);

  const [redirect, setRedirect] = useState(false);

  
  const logoutF = () => {
    dispatch(logout());
    navigate("/home");
    //TODO - remove use in redux
  };
  return (
    <div className='profile-card'>
        <div className='profile-header'>
            <p> {user ? t("welcome").replace("{userName}", user.username) : ""}</p>
        </div>
        <div className='profile-upper'>
            <div className='profile-avatar'>
                <div className='left'>

                    <div className='image-container'>
                        <div className="img-stack top">
                            <img src={                  user.avadarImage
                    ? PATH_PREFIX_AVATAR + user.avadarImage
                    : Constants.StudentDefaultImg} alt='profileimg'/>
                        </div>
                        <div className="img-stack bottom">
                            <FaCamera size={25} style={{color:"#D3D3D3", marginRight:"1rem"}} />
                        </div>
                    </div>
                    <div className='right'>
                        <p className='profile-name'>{user.username}</p>
                        <p className='profile-email'>{user.email}</p>
                    </div>
                </div> 
            </div>   
            <div className='profile-info'>
            <FaUserAlt size={20} style={{color:"#ff", marginRight:"1rem"}} /><button type='submit' className='btn'>个人信息</button>
            </div>
        </div>
        <hr />
        <div className='profile-middle'>
        <FaKey size={20} style={{color:"#ff", marginRight:"1rem"}} /><button type='submit' className='btn'>安全设置</button>
        </div> 
        <hr />       
        <div className='profile-middle'>
        <FaDollarSign size={20} style={{color:"#ff", marginRight:"1rem"}} /><button type='submit' className='btn'>缴费查询</button>
        </div>
        <hr />
        <div className='profile-bottom'>
        <FaSignOutAlt size={20} style={{color:"#ff", marginRight:"1rem"}} /><button className='btn' onClick={logoutF} >退出</button>
        </div> 
        <hr /> 
        <div className='profile-footer'>
            <p><a href="#">Privacy Policy </a> . <a href="#">Term of Service</a></p>
        </div>                               
    </div>
  );
}

export default ProfileCard