import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import * as Constants from "../config/Constants";

const Container = styled.div`
  margin: 10px 10px;
  width: 280px;
  height: 280px;
  border: 1px solid #d1c4c4;
  border-radius: 4%;
`;
const ImgCourse = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 4% 4% 0 0;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;
const Title = styled.div`
  font-size: 20px;
  padding-top: 5px;
  padding-left: 20px;
`;
const Line = styled.div`
  display: flex;
`;
const Hours = styled.div`
  font-size: 10px;
  width: 50%;
  padding-top: 10px;
  padding-left: 20px;
  text-align: left;
`;
const OrigPrice = styled.div`
  font-size: 14px;
  width: 20%;
  text-decoration: line-through;
  padding-top: 7px;
  text-align: right;
`;
const Price = styled.div`
  font-size: 20px;
  font-weight: bold;
  width: 25%;
  padding-top: 2px;
  padding-right: 15px;
`;

const HomeCourse = ({ course }) => {
  const EDU_STORAGE_URL = process.env.REACT_APP_EDU_STORAGE_URL;
  const PATH_PREFIX_PREVIEW = EDU_STORAGE_URL + Constants.PATH_PREFIX_PREVIEW;
  const ROUTE_PATH_COURSE_DETAIL = "/course-detail/" + course.courseId;

  return (
    <Container>
      <NavLink to={ROUTE_PATH_COURSE_DETAIL}>
        <ImgCourse
          alt="course preview image"
          src={PATH_PREFIX_PREVIEW + course.previewImage}
        />
      </NavLink>
      <Line>
        <Title>{course.courseName}</Title>
      </Line>
      <Line>
        <Hours>{(course.lessonCount * course.lessonDuration) / 60} HOURS</Hours>
        <OrigPrice>${course.originPrice}</OrigPrice>
        <Price>${course.price}</Price>
      </Line>
    </Container>
  );
};

export default HomeCourse;
