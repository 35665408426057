import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding-top: 30px;
  display: flex;
`;
const Title = styled.div`
  font-size: 14px;
`;
const Img = styled.img`
  border: none;
  border-radius: 50%;
  width: 88px;
  height: 88px;
`;
const About = () => {
  return (
    <Container>
      <Title>About</Title>
      <Img src="http://oseelight.ga/upload/test.gif" alt="test" />
    </Container>
  );
};

export default About;
