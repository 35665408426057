import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { Pagination } from "@mui/material";
import usePagination from "../components/Pagination";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser } from "../store/userSlice";
import { clearCourse } from "../store/courseSlice";
import { selectLanguage } from "../store/languageSlice";
import { setTeacher } from "../store/teacherSlice";
import { setClassmate } from "../store/classmateSlice";
import { clearLesson } from "../store/lessonSlice";
import { useNavigate } from "react-router-dom";
import * as Constants from "../config/Constants";
import dollarImg from "../../src/assets/images/dollar.png";
import addCartImg from "../../src/assets/images/add-cart.png";
import Link from "@mui/material/Link";
import NavigatorBar from "../components/common/NavigatorBar";
import { setMyCourses, clearMyCourses } from "../store/myCoursesSlice";
import {
  Container,
  Left,
  MainBody,
  AdsBody,
  Right,
  Header,
  FrameBorder,
  Navigator,
  Wrap,
  Title,
  Page,
  LineWrap,
  CourseImg,
  Img,
  CourseName,
  CourseDate,
  CourseInfo,
  Status,
  Label,
  Ads,
  ImgBlock,
  ErrorMsg,
} from "../components/common/Mainstyle";

const StudentCourse = () => {
  const today = new Date();
  const year = "" + today.getFullYear();
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const yearMonth = year.substring(2) + month;
  const time =
    ("0" + today.getHours()).slice(-2) +
    ("0" + today.getMinutes()).slice(-2) +
    ("0" + today.getSeconds()).slice(-2);

  const getLanguage = useSelector(selectLanguage);
  const navigate = useNavigate();
  let timeId = null;
  const [result, setResult] = useState("");
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let [page, setPage] = useState(1);
  const user = useSelector(selectUser);
  const studentId = user ? user.student.studentId : "";

  const EDU_STORAGE_URL = process.env.REACT_APP_EDU_STORAGE_URL;
  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;
  const NUM_OF_COURSE_PER_PAGE = process.env.REACT_APP_NUM_OF_COURSE_PER_PAGE;
  const PATH_PREFIX_PREVIEW = EDU_STORAGE_URL + Constants.PATH_PREFIX_PREVIEW
  const AXIOS_CONFIG = {...Constants.AXIOS_CONFIG_BASE};
  AXIOS_CONFIG.headers["x-token-subject"] = user.email;

  const count = Math.ceil(result.length / NUM_OF_COURSE_PER_PAGE);
  const pageData = usePagination(result, NUM_OF_COURSE_PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };

  const NavigatorHtml = () => {
    return (
      <Navigator>
        <Link underline="hover" color="inherit" href="/">
          {t("home_breadcrumb")}
        </Link>
        /
        <Link underline="none" color="text.primary" aria-current="page">
          {t("studentCourse")}
        </Link>
      </Navigator>
    );
  };

  const EDU_ORG_ID = process.env.REACT_APP_EDU_ORG_ID;
  const callCourseBysId = () => {
    axios
      .get(EDU_API_BASE_URL + "course/all?orgId=" + EDU_ORG_ID + "&sId=" + studentId, AXIOS_CONFIG)
      .then((response) => {
        console.log("course-all-res", response);
        setResult(response.data.data);
        dispatch(setMyCourses(response.data.data));
        setError("");
      })
      .catch((error) => {
        setError(error);
        console.log("course-all error", error);
      });
  };

  const logoutF = () => {
    dispatch(logout());
    dispatch(clearLesson());
    dispatch(clearCourse());
    dispatch(setTeacher(null));
    dispatch(setClassmate(null));
    dispatch(clearMyCourses());
    navigate("/home");
    //TODO - remove use in redux
  };

  //idle to logout
  const idle = () => {
    const minutes = true; // change to false if you'd rather use seconds
    const interval = minutes ? 60000 : 1000;
    const IDLE_TIMEOUT = 5; // 5 minutes in this example
    let idleCounter = 0;
    console.log("idle 5min");

    window.onload =
      document.onmousemove =
      document.onmouseup =
      document.onkeyup =
        () => {
          idleCounter = 0;
        };

    timeId = setInterval(() => {
      idleCounter++;
      if (idleCounter >= IDLE_TIMEOUT) {
        console.log("minite: ", idleCounter);
        //  clearInterval(timeId);
        logoutF(); // or whatever you want to do
      }
    }, interval);
  };

  useEffect(() => {
    if (!user) navigate("/login");
    i18n.changeLanguage(getLanguage);
    idle();
    console.log("yearmoth", yearMonth, " time ", time);
    callCourseBysId();

    return () => {
      clearInterval(timeId);
    };
  }, []);

  return (
    <Container>
      <Left />
      <MainBody>
        <NavigatorBar content={true} navigatorHtml={NavigatorHtml} />
        <FrameBorder>
          <Header> {t("myCourses")}</Header>
          {error ? (
            <ErrorMsg>{error.message}</ErrorMsg>
          ) : pageData.currentData() ? (
            pageData.currentData().map((course) => (
              <LineWrap key={course.courseId}>
                <CourseImg>
                  <NavLink to={"/course-detail/" + course.courseId}>
                    <Img src={PATH_PREFIX_PREVIEW + course.previewImage} />
                  </NavLink>
                </CourseImg>
                <CourseInfo>
                  <CourseName>{course.courseName}</CourseName>
                  <CourseDate>
                    {" "}
                    {course.startDate} to {course.endDate}
                    &nbsp; {course.timeRange}
                  </CourseDate>
                  <CourseName>{course.teachers[0]?course.teachers[0].firstName:"undecided"}</CourseName>
                </CourseInfo>
                <Status>
                  {course.courseStatus == 4 ? (
                    <ImgBlock alt={`purchased`} src={dollarImg} />
                  ) : (
                    <ImgBlock alt={`add to cart`} src={addCartImg} />
                  )}
                </Status>
              </LineWrap>
            ))
          ) : (
            "No Data Found"
          )}
          <Page>
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </Page>
        </FrameBorder>
      </MainBody>
      <AdsBody>
        <NavigatorBar content={false} navigatorHtml={NavigatorHtml} />
        <FrameBorder>
          <Ads src={Constants.AdsImg_1} />
        </FrameBorder>
      </AdsBody>
      <Right />
    </Container>
  );
};

export default StudentCourse;
