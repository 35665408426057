import React , {useState, useEffect} from "react";
import styled from "styled-components";


const InfoRight = styled.div`
width: 100%;
height: 20px;
border: solid 1px green;
color: green;
margin-top: -25px;
align-items: center;
  justify-content: center;
font-size: 13px;
background-color: lightblue;

`;

const InfoError = styled.div`
width: 100%;
height: 20px;
border: solid 1px red;
color: red;
margin-top: -25px;
align-items: center;
  justify-content: center;
font-size: 13px;
background-color: lightpink;

`;

const Message = ({message, error}) => { 

 
  return (
   
        <div>

           {  error? <InfoError>{message} </InfoError>

            : <InfoRight>{message}</InfoRight> 
           }
      
        </div>     
     
   
  );
};

export default Message;
