import { createSlice } from "@reduxjs/toolkit";

export const lessonSlice = createSlice({
  name: "lesson",
  initialState: { lesson: null },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLesson: (state, action) => {
      state.value = action.payload;
    },
    clearLesson: (state) => {
      state.value = null;
    },
  },
});

export const { setLesson, clearLesson } = lessonSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLesson = (state) => state.lesson.value;

export default lessonSlice.reducer;
