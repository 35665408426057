import axios from "axios";
import React, { useState, useEffect } from "react";
import { Pagination } from "@mui/material";
import usePagination from "./Pagination";
import styled from "styled-components";

import HomeCourse from "./HomeCourse";
import { withTranslation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLanguage } from "../store/languageSlice";
import * as Constants from "../config/Constants";

const Container = styled.div`
  width: 100%;
  position: relative;
  margin-top: -145px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
const Left = styled.div`
  flex: 1;
`;
const Right = styled.div`
  flex: 1;
`;
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background: white;
  justify-content: center;
  border-radius: 7px;
`;
const Page = styled.div`
  flex: 5;
  margin: 50px 50px;
  border: 1px solid #d1c4c4;
  border-radius: 7px;
  box-shadow: 2px 3px 40px #bbb0b0;
  justify-content: center;
`;
const Line = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 15px;
`;
const PageNumber = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px;
`;
const AllCourseButton = styled.button`
  font-size: 15px;
  color: white;
  background-color: ${Constants.NavigationBackgroundColor};
  height: 33px;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
`;
const CategoryButton = styled.div``;
const Button = styled.button`
  font-size: 15px;
  color: #8f8686;
  height: 33px;
  border: 1px solid #b3a9a9;
  border-radius: 2px;
  cursor: pointer;
`;
const ErrorMsg = styled.div`
  font-size: 15px;
  color: #f30808;
`;

export const HomeCourseList = () => {
  const getLanguage = useSelector(selectLanguage);
  const { t, i18n } = useTranslation();
  
  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;
  const NUM_OF_COURSE_PER_PAGE = process.env.REACT_APP_NUM_OF_COURSE_PER_PAGE;

  let [page, setPage] = useState(1);
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");

  const count = Math.ceil(response.length / NUM_OF_COURSE_PER_PAGE);
  const pageData = usePagination(response, NUM_OF_COURSE_PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };

  const EDU_ORG_ID = process.env.REACT_APP_EDU_ORG_ID;
  useEffect(() => {
    i18n.changeLanguage(getLanguage);
    axios
      .get(EDU_API_BASE_URL + "course/all?orgId=" + EDU_ORG_ID, Constants.AXIOS_CONFIG_BASE)
      .then((response1) => {
        console.log("respon", response1.data);
        setResponse(response1.data.data);
        console.log("response", response);
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      });
  }, []);

  return (
    <Container>
      <Left />
      <Page>
        <Wrapper>
          <Line>
            <AllCourseButton>{t("all_courses")}</AllCourseButton>
            <CategoryButton>
              <Button>{t("new_courses")}</Button>
              <Button>{t("hot_courses")}</Button>
              <Button>{t("recommended_courses")}</Button>
            </CategoryButton>
          </Line>
          {error ? (
            <ErrorMsg>{error.message}</ErrorMsg>
          ) : pageData.currentData() ? (
            pageData
              .currentData()
              .map((course) => (
                <HomeCourse key={course.courseId} course={course} />
              ))
          ) : (
            "No Data Found"
          )}
        </Wrapper>
        <PageNumber>
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </PageNumber>
      </Page>
      <Right />
    </Container>
  );
};

export default withTranslation()(HomeCourseList);
